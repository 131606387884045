<template>
    <div class="collectData_page">
        <div class="collect_data flex flex-wrap">
            <div class="collect_data_list flex-center column" v-for="(item, index) in collectList" :key="index">
                <img class="list_img" :src="item.preparationMaterialsImgUrl" alt="">
                <div class="name">{{ item.preparationMaterialsName }}</div>
                <div class="btn pointer display" @click="service">咨询获取</div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        collectList: {}
    },
    data() {
        return {

        }
    },
    methods: {
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    }
}
</script>
<style lang="scss" scoped>
.collectData_page {
    .collect_data {
        margin-top: 31px;

        .collect_data_list:nth-child(6n+1) {
            margin-left: 0 !important;
        }

        .collect_data_list {
            margin-left: 30px;
            margin-bottom: 30px;

            .list_img {
                width: 90px;
                height: 90px;
            }

            .name {
                width: 174px;
                height: 45px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 16px;
                color: #333333;
                margin: 19px 0;
                text-align: center;
                overflow: hidden;
                overflow-y: scroll;
                line-height: 24px;
            }

            .name::-webkit-scrollbar {
                display: none;
            }

            .btn {
                width: 120px;
                height: 40px;
                background: #FF881E;
                border-radius: 5px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
}</style>