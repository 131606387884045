<template>
    <div class="LearningMaterials_page">
        <CourseTitle  :title="courseTitle" contText=""> </CourseTitle>
        <div class="content learning_materials flex flex-wrap">
            <div class="img_box">
                    <img class="learning_materialson_img" v-for="(item,index) in options" :key="index" :src="item.teachingMaterialsImgUrl" alt="" />
            </div> 
                <div class="formData flex-center column">
                    <div class="title">教辅资料预约申领平台</div>
                    <div class="list flex-center">
                        <img class="list_img" src="@/assets/img/certificate/name.png" alt="">
                        <input type="text" v-model="params.userName" placeholder="请填写您的姓名">
                    </div>
                    <div class="list flex-center">
                        <img class="list_img" src="@/assets/img/certificate/phone.png" alt="">
                        <input type="text" maxlength="11" v-model="params.userPhone" placeholder="请填写您的手机号">
                    </div>
                    <div class="list list_book flex-center">
                        <img class="list_img" src="@/assets/img/certificate/book.png" alt="">
                        <el-select v-model="params.teachingMaterialsName" placeholder="请选择您要申领的资料"  @change="selectBtn">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="btn pointer display" @click="receiveBtn">立即申领教辅资料</div>
                </div>
            </div>
    </div>
</template>
<script>
    import CourseTitle from '../views/homes/components/courseTitle.vue'
export default {
    props:{
        courseTitle:{},
        options:{},
        type:{}
    },
    components:{
        CourseTitle
    },
    data(){
        return{
            value:'',
            params:{
                type:null,
                teachingMaterialsId:"",
                teachingMaterialsName:"",
                userName:"",
                userPhone:"",
            }
        }
    },
    methods:{
        selectBtn(val){
            this.params.teachingMaterialsId = val.value
            this.params.teachingMaterialsName = val.label
        },
        receiveBtn(){
            this.params.type = this.type
            var reg = /^1(3|4|5|6|7|8|9)\d{9}$/
            if (this.params.userName == ''){
               return this.$message.error('请输入姓名')
            } else if (!reg.test(this.params.userPhone)) {
                return this.$message.error('请输入手机号')
            } else if (this.params.teachingMaterialsName == '') {
                return this.$message.error('请选择您要申领的资料')
            }
            this.api.addGetInformation(this.params).then(res=>{
                if(res.code == 0){
                    this.$message.success('成功')
                    this.params = {
                        type:1,
                        teachingMaterialsId:"",
                        teachingMaterialsName:"",
                        userName:"",
                        userPhone:"",
                    }
                }else{
                    this.$message.error(res.msg)
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .LearningMaterials_page{  
        height: 728px;  
        background: url('../assets/img/course/bg9.png')no-repeat;
        background-size: 100% 100%;
          .learning_materials{
                width: 1200px;
                height: 458px;
                background: #FFFFFF;
                border-radius: 8px;
                padding: 24px 78px 24px 51px;
                box-sizing: border-box;
                margin-top: 31px;
                .img_box{
                    width: 592px;
                    .learning_materialson_img:nth-child(4n+1){
                        margin-left: 0 !important;
                    }
                    .learning_materialson_img{
                        width: 110px;
                        height: 110px;
                        margin-left: 42px;
                        margin-bottom: 30px;
                    }
                }
                .formData{
                    margin-top: 32px;
                    margin-left: 49px;
                    .title{
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #003C7E;
                        line-height: 24px;
                        margin-bottom: 39px;
                    }
                    .list{
                        width: 430px;
                        height: 48px;
                        background: #FAFAFA;
                        border: 1px solid #DEDEDE;
                        border-radius: 5px;
                        margin-bottom: 20px;
                         .list_img{
                            width: 29px;
                            height: 28px;
                            margin-left: 20px;
                         }
                         input{
                            width: 398px;
                            height: 40px;
                            border-radius: 5px;
                            font-size: 16px;
                            margin-left: 16px;
                            background: #FAFAFA;
                         }
                        
                    }
                    .list_book{
                        position: relative;
                        .list_img{
                            position: absolute;
                            top: 9px;
                            left: 0px;
                            z-index: 9;
                        }
                        ::v-deep.el-input__inner{
                           width: 420px;
                           height: 44px;
                           background: #FAFAFA;
                           border: none;
                           font-size: 16px;
                           padding-left:65px;
                           color: #333;
                        }
                    }
                    .btn{
                        width: 430px;
                        height: 48px;
                        background: #FF881E;
                        border-radius: 5px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-top: 10px;
                    }
                   
                }
            }
    }
</style>