<template>
    <div class="examinationInformation_page">
        <CourseTitle :title="title" :contText="contText"> </CourseTitle>
        <div class="examination_information content display">
            <div class="fl">
                <div class="title display">
                    各地<span> 报考信息</span>咨询入口
                </div>
                <div class="region flex flex-wrap">
                    <div class="list display pointer" @click="service" v-for="(item, index) in province" :key="index">
                        {{ item.provinceName }}</div>
                    <div class="line"></div>
                </div>
            </div>
            <div class="fr">
                <div class="title display">
                    考生<span> 首次报考</span>常见疑问
                </div>
                <div class="doubt">
                    <div class="doubt_list Between" v-for="(item, index) in question" :key="index">
                        <span class="display">{{ index + 1 }}</span>
                        <div class="doubt_title">{{ item.questionName }}</div>
                        <div class="consultBtn display pointer" @click="service">立即咨询</div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CourseTitle from '../views/homes/components/courseTitle.vue'
export default {
    props: {
        title: {},
        contText: {},
        province: {},
        question: {},
    },
    components: {
        CourseTitle
    },
    data() {
        return {
            region: [],
        }
    },
    methods: {
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    }
}
</script>
<style lang="scss" scoped>
.examinationInformation_page {
    width: 100%;
    height: 894px;
    background: url('../assets/img/course/bg4.png') no-repeat;
    background-size: 100% 100%;

    .examination_information {
        margin-top: 68px;

        .title {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            line-height: 24px;
            padding-top: 41px;

            span {
                color: #FF881E;
            }
        }

        .fl {
            width: 468px;
            height: 550px;
            background: #FFFFFF;
            border-radius: 5px;
            padding: 0px 36px;

            .region {
                margin-top: 43px;
                position: relative;

                .line {
                    width: 100%;
                    height: 2px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    background: #FFFFFF;
                }

                .list:nth-child(4n+1) {
                    border-left: 0;
                }

                .list:last-child {
                    border-right: 1px solid #DEDEDE !important;
                }

                .list {
                    width: 25%;
                    height: 65px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    border: 1px solid #DEDEDE;
                    border-top: 0;
                    border-right: 0;

                }
            }
        }

        .fr {
            width: 668px;
            height: 550px;
            background: #003C7E;
            border-radius: 5px;
            margin-left: 8px;

            .title {
                color: #fff;
                margin-top: 4px;
                margin-bottom: 24px;
            }

            .doubt::-webkit-scrollbar {
                display: none;
            }

            .doubt::-webkit-scrollbar {
                display: none;
            }

            .doubt {
                height: 430px;
                padding: 0 23px;
                box-sizing: border-box;
                margin-top: 17px;
                padding-left: 45px;
                overflow: hidden;
                overflow-y: scroll;

                .doubt_list {
                    width: 580px;
                    height: 50px;
                    background: #005ABC;
                    border-radius: 24px;
                    padding-left: 36px;
                    padding-right: 20px;
                    box-sizing: border-box;
                    margin-bottom: 10px;
                    position: relative;

                    span {
                        width: 30px;
                        height: 30px;
                        background: #EEF6FA;
                        border-radius: 50%;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #003C7E;
                        position: absolute;
                        top: 50%;
                        left: -15px;
                        margin-top: -15px;
                    }

                    .doubt_title {
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FFFFFF;

                    }

                    .consultBtn {
                        width: 124px;
                        height: 34px;
                        border: 1px solid #EEF6FA;
                        border-radius: 17px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
}</style>